.topbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.googleBtn {
    width: 225px;
    margin: 0 0 50px 0;
    display: flex;
    justify-content: center;
    font-size: 16px;
}
  
.googleBtn img {
    width: 16px;
    height: 16px;
    padding: 0;
    margin: 0 5px;
    vertical-align: middle;
}